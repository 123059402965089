<script setup lang="ts">
import type { ShopByBudgetParams } from './HomeShopByBudget.vue'
import UiInfoTooltip from '~/components/ui/UiInfoTooltip.vue'
import { useFormatNumberInput } from '~/composables/useFormatNumberInput'
import { creditScoreOptions } from '~/shared/creditScoreOptions'

defineProps<{ tab: ShopByBudgetParams['mode'] }>()

defineEmits(['inputChange'])

const termOptions = [12, 24, 36, 48, 60, 72]

const params = defineModel<ShopByBudgetParams>('params', { required: true })

const formattedPrice = useFormatNumberInput(toRefs(params.value).vehiclePrice, { format: 'price', blankOnZero: true })
const formattedPayment = useFormatNumberInput(toRefs(params.value).real_payment_amount, { format: 'price', blankOnZero: true })
const formattedDownpayment = useFormatNumberInput(toRefs(params.value).real_down_payment, { format: 'price', blankOnZero: true })
const formattedTradeIn = useFormatNumberInput(toRefs(params.value).trade_in_value, { format: 'price', max: params.value.vehiclePrice, blankOnZero: true })
</script>

<template>
  <div class="grid gap-5 xs:grid-cols-[repeat(auto-fill,minmax(220px,1fr))]">
    <div v-if="tab === 'monthly_payment'" class="flex flex-col gap-1">
      <label class="self-start text-sm" for="budget-max-price">Maximum vehicle price</label>
      <input id="budget-max-price" v-model="formattedPrice" type="text" @input="$emit('inputChange')">
    </div>
    <div v-else class="flex flex-col gap-1">
      <label class="self-start text-sm" for="budget-monthly-payment">Monthly car payment</label>
      <input id="budget-monthly-payment" v-model="formattedPayment" type="text" @input="$emit('inputChange')">
    </div>

    <div class="flex flex-col gap-1">
      <label class="self-start text-sm" for="budget-down-payment">Down payment</label>
      <input id="budget-down-payment" v-model="formattedDownpayment" type="text" @input="$emit('inputChange')">
    </div>
    <div class="flex flex-col gap-1">
      <label class="self-start text-sm" for="budget-term-length">Term length</label>
      <select id="budget-term-length" v-model="params.real_term">
        <option v-for="term in termOptions" :key="term" :value="term">
          {{ term }} months
        </option>
      </select>
    </div>
    <div class="flex flex-col gap-1">
      <div class="flex items-center gap-[2px]">
        <label class="self-start text-sm" for="budget-credit-score">Credit score</label>
        <UiInfoTooltip v-if="false">
          Credit Score Tooltip
        </UiInfoTooltip>
      </div>
      <select id="budget-credit-score" v-model="params.c_range">
        <option
          v-for="option in creditScoreOptions" :key="option.label" :value="option.value"
          :selected="option.value === params.c_range"
        >
          {{ option.label }}
        </option>
      </select>
    </div>
    <div class="flex flex-col gap-1">
      <div class="flex items-center gap-[2px]">
        <label class="self-start text-sm" for="budget-trade-in">Trade-in (optional)</label>
        <UiInfoTooltip v-if="false">
          Trade In Tooltip Text
        </UiInfoTooltip>
      </div>
      <input id="budget-trade-in" v-model="formattedTradeIn" type="text" @input="$emit('inputChange')">
    </div>
    <div class="flex flex-col gap-1">
      <div class="flex items-center gap-[2px]">
        <label class="self-start text-sm" for="budget-zipcode">ZIP code</label>
        <UiInfoTooltip>Zip code provides a more accurate estimate of your taxes and fees.</UiInfoTooltip>
      </div>
      <LazyUiZipPicker id="zip-code-picker-shop-by-budget" v-model="params.zip" />
    </div>
  </div>
</template>
