<script setup lang="ts">
const { $trackEvent } = useNuxtApp()
const showZipDialog = ref(false)
const showGetStartedDialog = ref(false)
const { totalResults } = storeToRefs(useHomePageDataStore())
const visitor = useVisitorStore()
const range = computed(() => useRoute().query.range as string || '50')

function startQuiz() {
  $trackEvent('Quiz started')
  showGetStartedDialog.value = true
}
</script>

<template>
  <div class="bg-blue-light-10 bg-[url('~/assets/icons/bg-swirl.svg')] bg-fit bg-bottom">
    <UiContainer class="relative">
      <div
        class="absolute inset-0 bottom-8 bg-[url('~/assets/images/hero-small@2x.avif')] bg-contain bg-top bg-no-repeat md:left-[140px] md:right-8 md:rounded-2xl md:bg-[url('~/assets/images/hero@2x.avif')] md:bg-cover md:shadow-xlarge lg:left-auto lg:w-[846px]"
      />
      <div class="flex flex-col">
        <div
          class="relative mx-0 mb-4 mt-[142px] flex flex-col gap-3 rounded-2xl bg-white p-4 shadow-xlarge md:mb-20 md:mt-12 md:max-w-[547px] md:p-8"
        >
          <h1 class="text-4xl font-bold md:text-5xl">
            Online car shopping with confidence
          </h1>
          <ul class="mb-3 grid gap-2">
            <li class="flex gap-2">
              <Icon name="heroicons:check-circle" class="size-6 text-green" />
              Unbiased data driven vehicle insights
            </li>
            <li class="flex gap-2">
              <Icon name="heroicons:check-circle" class="size-6 text-green" />
              4 million+ new and used vehicles
            </li>
            <li class="flex gap-2">
              <Icon name="heroicons:check-circle" class="size-6 text-green" />
              Helpful tools for finding the best fit
            </li>
          </ul>
          <p class="font-semibold">
            <template v-if="visitor.location?.zip">
              Shop {{ formatNumber(totalResults) }} new and used vehicles near
            </template>
            <template v-else>
              Shop new and used vehicles near your location.
            </template>

            <button class="text-blue underline" @click="showZipDialog = true">
              <span v-if="visitor.location && visitor.location.zip">{{ `${visitor.location.city}, ${visitor.location.state}` }}</span>
              <span v-else>Set Your Zip Code</span>
            </button>
          </p>
          <UiTextSearch :zip-code="useVisitorStore().location?.zip" />
          <p
            class="m-0 flex items-center font-semibold uppercase before:h-px before:flex-1 before:bg-gray-3 after:h-px after:flex-1 after:bg-gray-3"
          >
            <span class="px-3">Or</span>
          </p>
          <div class="flex flex-col flex-wrap items-stretch gap-4 rounded-lg bg-gray-4 p-3 md:flex-row md:items-center">
            <p class="flex flex-1 items-center gap-1">
              <Icon name="carstory:heroicons-outline-sparkles-modified" class="size-10 shrink-0 text-blue" />
              Answer a few quick questions and we’ll find you the best fit.
            </p>
            <UiButton @click.prevent="startQuiz">
              Get started
            </UiButton>
          </div>
        </div>
      </div>
    </UiContainer>

    <ClientOnly>
      <LazyDialogChangeZip v-model="showZipDialog" :zip-code="visitor.location?.zip as string" />
      <LazyDialogGetStarted v-model="showGetStartedDialog" :zip="visitor.location?.zip as string" :range="range" />
    </ClientOnly>
  </div>
</template>
