<template>
  <UiPopover>
    <template #trigger>
      <button class="rounded-full p-[2px]" type="button">
        <Icon name="heroicons:information-circle-16-solid" class="block size-4" />
      </button>
    </template>
    <slot>
      Info Tooltip Text
    </slot>
  </UiPopover>
</template>
