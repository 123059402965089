<script setup lang="ts">
import { UrlBuilder } from '~/utils/UrlBuilder'

const {
  lastSearch,
  loading,
  hasLastSearch,
  results,
  lastCommonParams,
} = useLastSearchStore()

const commonParams = computed(() => {
  return { ...lastCommonParams, ...lastSearch } as { zip?: string }
})

const items = computed(() => {
  const minResults = 10
  const label = Object.values(lastSearch).join(' ')
  return results
    .filter(item => item[1] >= minResults)
    .map(([params, count, tpl]) => ({
      count,
      title: tpl.replace('%', label),
      href: new UrlBuilder('search', { ...commonParams.value, ...params }).href(),
    }))
})
</script>

<template>
  <section v-if="hasLastSearch && items.length" class="my-12">
    <h2 class="text-sm font-semibold uppercase tracking-[0.5px] text-blue-dark">
      Shop by recent searches
    </h2>

    <div
      class="mt-6 grid gap-4 md:grid-cols-[repeat(auto-fit,minmax(250px,1fr))]"
      :class="{ 'cs-blur': loading }"
    >
      <UiCard v-for="item in items" :key="item.href" as-child>
        <NuxtLink :to="item.href">
          <div class="mb-2 flex items-center justify-between">
            <p class="flex gap-2 text-sm font-semibold uppercase tracking-[0.5px] text-blue-dark">
              <Icon name="heroicons:magnifying-glass-20-solid" class="size-[18px] [transform:rotateY(180deg)]" />
              Searched
            </p>
            <p class="rounded-full bg-blue px-3 py-[2px] text-sm font-semibold text-white">
              {{ formatNumber(item.count) }} vehicles
            </p>
          </div>
          <p class="mb-1 text-xl font-semibold">
            {{ item.title }}
          </p>
          <p> {{ commonParams.zip ? `Near ${commonParams.zip}` : 'Nationwide' }}</p>
        </NuxtLink>
      </UiCard>
    </div>
  </section>
</template>

<style scoped>
.cs-blur {
  filter: blur(1px);
}

.cs-blur p {
  filter: blur(3px);
}
</style>
