<script setup lang="ts">
const homePageData = useHomePageDataStore()

await Promise.all([
  homePageData.initialize(),
  useLastSearchStore().fetchData(),
])
</script>

<template>
  <div>
    <HomeSearchForm />

    <UiContainer>
      <HomeRecentSearches />

      <section class="my-12">
        <h2 class="text-sm font-semibold uppercase tracking-[0.5px] text-blue-dark">
          Why CarStory?
        </h2>
        <p class="mb-[30px] text-2xl font-bold md:text-3xl">
          Transparent insights & confident car shopping decisions
        </p>
        <div class="grid gap-6 md:grid-cols-[repeat(auto-fit,minmax(200px,1fr))]">
          <div class="border-b border-gray-3 pb-6 md:border-b-0 md:border-r md:pb-0 md:pr-6">
            <Icon name="heroicons:banknotes" class="mb-3 block text-green" />
            <h3 class="mb-2 text-xl font-semibold">
              Data-driven pricing guidance
            </h3>
            <p>
              CarStory offers guidance on vehicle pricing, ensuring you make informed decisions and get the best value
              for your vehicle.
            </p>
          </div>
          <div class="border-b border-gray-3 pb-6 md:border-b-0 md:border-r md:pb-0 md:pr-6">
            <Icon name="heroicons:presentation-chart-line" class="mb-3 block size-6 text-blue-light" />
            <h3 class="mb-2 text-xl font-semibold">
              Market trend tracking & transparency
            </h3>
            <p>
              Stay ahead of the curve. With access to real-time data, you can navigate the market with confidence and
              clarity.
            </p>
          </div>
          <div>
            <Icon name="heroicons:light-bulb" class="mb-3 block size-6 text-yellow" />
            <h3 class="mb-2 text-xl font-semibold">
              Simple and unbiased vehicle research
            </h3>
            <p>
              Simplify your vehicle research with CarStory. Our platform offers easy-to-use tools and unbiased insights.
            </p>
          </div>
        </div>
      </section>

      <HomeShopByBudget />
      <HomeShopByVehicleType />
    </UiContainer>
  </div>
</template>
