<script setup lang="ts">
const { zipCode } = storeToRefs(useVisitorStore())

const bodyStyles = reactive([
  'Pickup',
  'SUV',
  'Sedan',
  'Coupe',
  'Convertible',
  'Hatchback',
  'Minivan',
  'Wagon',
])

const activeStyle = ref(bodyStyles[0])
const loading = ref()
const isExpanded = ref(false)

const { data } = useAsyncData('shop-by-trends', async () => {
  loading.value = true
  let result
  try {
    result = await $fetch('/api/attributes/shop-by-trends', {
      query: {
        body_style: activeStyle.value,
        zip: zipCode.value,
      },
    })
  }
  catch (e: unknown) {
    console.error('Failed fetching shop-by-trends data', e)
  }
  loading.value = false
  return result ? result.slice(0, 10) : []
}, {
  watch: [activeStyle],
  immediate: true,
})

function getImageUrl(make: string) {
  return `/images/makes/${make.split(',')[0].toLowerCase()}.avif`
}

function getUrl(item: { make: string, id: string }) {
  return UrlBuilder.createSearchUrl({
    make: item.make,
    model: item.id,
    zip: zipCode.value,
    body_style: activeStyle.value,
  })
}
</script>

<template>
  <section class="my-12">
    <h2 class="text-sm font-semibold uppercase tracking-[0.5px] text-blue-dark">
      Shop by Trends
    </h2>
    <p class="text-2xl font-bold md:text-3xl">
      Find the most searched vehicles
    </p>

    <UiTabs :default-value="`tab_${activeStyle}`" class="flex flex-col gap-[10px] md:gap-6">
      <UiTabsList
        aria-label="Shop by trends"
      >
        <UiTabsIndicator />
        <UiTabsTrigger v-for="style in bodyStyles" :key="style" :value="`tab_${style}`" @click="activeStyle = style">
          {{ style }}s
        </UiTabsTrigger>
      </UiTabsList>

      <UiTabsContent v-for="style in bodyStyles" :key="style" :value="`tab_${style}`">
        <div
          class="mb-2 grid gap-2 transition delay-150 sm:grid-cols-[repeat(auto-fill,minmax(200px,1fr))]"
          :class="{ 'opacity-50': loading }"
        >
          <UiCard
            v-for="item in data"
            :key="`${item.make}-${item.id}`"
            class="cs-card group flex items-center gap-[10px] py-[7px] text-sm"
            :class="{ 'cs-expanded': isExpanded }"
            as-child
          >
            <NuxtLink :to="getUrl(item)">
              <img
                :src="getImageUrl(item.make)"
                class="my-2 size-12 grayscale transition group-hover:filter-none"
                loading="lazy"
                alt=""
              >
              <div>
                <p class="font-semibold">
                  {{ item.make }} {{ item.id }}
                </p>
                <p class="text-gray-1">
                  {{ formatNumber(item.count) }} vehicles
                </p>
              </div>
            </NuxtLink>
          </UiCard>
        </div>
      </UiTabsContent>
    </UiTabs>

    <UiButton
      variant="outline"
      aria-live="polite"
      class="w-full items-center gap-[10px] sm:hidden"
      @click="isExpanded = !isExpanded"
    >
      {{ isExpanded ? 'View less' : 'View more' }}
      <!-- TODO: Check why this <Icon> below prevents `npm run dev` from starting -->
      <Icon name="carstory:caret-down" class="size-3" :class="{ 'rotate-180': isExpanded }" />
      <!-- <span class="h-2 w-3 bg-[url('~/assets/icons/caret-down.svg')] bg-center bg-no-repeat" /> -->
    </UiButton>
  </section>
</template>

<style scoped>
/* Hide the last 5 cards on mobile and show them when expanded */
.cs-card:nth-child(n+6) {
  @apply hidden sm:flex;
}

.cs-card.cs-expanded:nth-child(n+6) {
  @apply flex;
}
</style>
