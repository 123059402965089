<script setup lang="ts">
import { useDebounceFn } from '@vueuse/core'
import HomeShopByBudgetTabContent from '~/components/home/HomeShopByBudgetTabContent.vue'
import type { FacetList, SapiAttributes } from '~/types/CarstoryApiTypes'

const { setShopByBudgetParams, shopByBudgetParams } = useShopByBudgetStore()

export type ShopByBudgetParams = typeof shopByBudgetParams

const visitorStore = useVisitorStore()

const { data: limits } = useFetch('/api/attributes/search-filters', {
  query: { zip: visitorStore.zipCode },
  transform: (input: SapiAttributes) => {
    const facet = input.facets.find(f => f.id === 'price') as Partial<FacetList>
    delete facet?.values
    return facet as Required<FacetList>
  },
})

const params = reactive(shopByBudgetParams)
params.zip = visitorStore.zipCode || params.zip

const { data, status, refresh } = await useAsyncData('shop-by-budget-data', async () => {
  const query = { ...params, 'x-cs': 'shop-by-budget', 'limit': 0 } as Record<string, any>
  try {
    return await $fetch('/api/attributes/shop-by-budget', { query })
  }
  catch (_e: unknown) {
    console.log(_e)
  }
})

const leavingPage = ref(false)

function navigate() {
  let url: string
  if (params.mode === 'vehicle_budget' && data.value) {
    url = UrlBuilder.createSearchUrl({
      price: `min-${data.value.amount}`,
      zip: params.zip || '',
    })
    params.vehiclePrice = data.value.amount
  }
  else {
    url = UrlBuilder.createSearchUrl({
      price: `min-${params.vehiclePrice}`,
      real_payment_amount: `0-${data.value?.amount}`,
      zip: params.zip || '',
    })
  }
  setShopByBudgetParams(params)
  leavingPage.value = true
  return navigateTo(url, {
    external: true,
  })
}

function refreshData() {
  if (leavingPage.value)
    return
  if (params.mode === 'monthly_payment' && limits.value) {
    if (!params.vehiclePrice)
      params.vehiclePrice = 0
    if (params.vehiclePrice > limits.value.upper)
      params.vehiclePrice = limits.value?.upper as number
    if (params.real_down_payment > Math.round(params.vehiclePrice * 0.8))
      params.real_down_payment = Math.round(params.vehiclePrice * 0.8)
  }
  refresh()
}

const componentKey = ref(JSON.stringify(params))

const refreshDataDebounced = useDebounceFn(() => {
  refreshData()
}, 500)

const isLoading = computed(() => !status.value || status.value === 'pending')
</script>

<template>
  <section id="shop-by-budget" :key="componentKey" class="my-12" :class="{ 'cs-loading': isLoading }">
    <h2 class="text-sm font-semibold uppercase tracking-[0.5px] text-blue-dark">
      Shop by Budget
    </h2>

    <p class="mb-6 text-2xl font-bold md:text-3xl">
      Discover what you can afford and vehicles that fit your budget
    </p>

    <div class="flex flex-col gap-6 md:flex-row">
      <UiTabs
        class="flex w-full flex-col gap-4"
        :model-value="params.mode"
        @update:model-value="params.mode = $event as ShopByBudgetParams['mode']; refreshData()"
      >
        <UiTabsList
          aria-label="Shop by budget"
          variant="rounded"
        >
          <UiTabsIndicator variant="rounded" />
          <UiTabsTrigger
            variant="rounded"
            value="monthly_payment"
          >
            Monthly payment
          </UiTabsTrigger>
          <UiTabsTrigger
            variant="rounded"
            value="vehicle_budget"
          >
            Vehicle budget
          </UiTabsTrigger>
        </UiTabsList>

        <UiTabsContent value="monthly_payment">
          <form @change="refreshData">
            <HomeShopByBudgetTabContent v-model:params="params" tab="monthly_payment" @input-change="refreshDataDebounced" />
          </form>
        </UiTabsContent>
        <UiTabsContent value="vehicle_budget">
          <form @change="refreshData">
            <HomeShopByBudgetTabContent v-model:params="params" tab="vehicle_budget" @input-change="refreshDataDebounced" />
          </form>
        </UiTabsContent>
      </UiTabs>

      <div
        v-if="data"
        class="flex flex-col gap-4 rounded-lg bg-blue-light-10 p-6 text-center md:min-w-[270px] lg:min-w-[404px] lg:p-12"
      >
        <img src="~assets/icons/piggy-bank.svg" alt="" class="self-center" loading="lazy" width="64" height="58">
        <div>
          <p class="text-sm font-semibold uppercase">
            <span class="mr-1 align-middle">
              {{ params.mode === 'monthly_payment' ? 'Estimated Monthly Payment' : 'Estimated Vehicle Budget' }}
            </span>
            <UiPopover>
              <p>A financed amount of {{ formatPrice(data.vehiclePrice) }} includes {{ formatPrice(data.fees) }} estimated tax and fees.</p>
            </UiPopover>
          </p>

          <p class="text-4xl font-bold">
            <UiAnimatedNumber format="price" :value="data.amount || 0" />
          </p>

          <p class="min-h-[1lh]">
            <span v-if="data.apr">
              est. {{ (data.apr * 100).toFixed(2) }}% APR
            </span>
          </p>
        </div>
        <UiButton class="inline-block delay-200 disabled:border-blue disabled:bg-blue disabled:text-white disabled:opacity-50" :disabled="!data.matches || !data.amount || leavingPage || isLoading" @click="navigate">
          See
          <UiAnimatedNumber :value="!data.amount ? 0 : data.matches || 0" />
          vehicles
        </UiButton>
      </div>
    </div>
  </section>
</template>
