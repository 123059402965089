export const creditScoreOptions = [
  {
    label: 'Excellent (760+)',
    value: 800,
    category: 'Excellent',
  },
  {
    label: 'Very Good (700-759)',
    value: 750,
    category: 'Very Good',
  },
  {
    label: 'Good (630-699)',
    value: 690,
    category: 'Good',
  },
  {
    label: 'Fair (550-629)',
    value: 590,
    category: 'Fair',
  },
  {
    label: 'Poor (300-549)',
    value: 400,
    category: 'Poor',
  },
]
