<script setup lang="ts">
import { TransitionPresets, useTransition } from '@vueuse/core'

interface Props {
  value: number
  format?: 'number' | 'price'
}

const props = withDefaults(defineProps<Props>(), {
  value: 0,
  type: 'number',
})

const source = ref(0)

watch(() => props.value, (newValue) => {
  source.value = newValue
}, { immediate: true })

const output = useTransition(source, {
  duration: 500,
  transition: TransitionPresets.easeOutSine,
})

const counter = computed(() => {
  if (props.format === 'price') {
    return formatPrice(Math.ceil(output.value))
  }
  else {
    return formatNumber(Math.ceil(output.value))
  }
})
</script>

<template>
  <span>
    {{ counter }}
  </span>
</template>
